import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  NgZone,
} from '@angular/core';
import { MenuItem } from '@models/MenuItem';
import { PiwikActions, PiwikCategories } from '@models/Piwik';
import { Store } from '@ngrx/store';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { redirectTo } from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import {
  getHeaderMenuItems,
  getRouterStateUrl,
} from '@store/root/root.selectors';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sgxb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public route$: Observable<string>;
  public menuItems$: Observable<MenuItem[]>;
  public containerClass = 'container';
  public envName = '';

  @HostListener('sgwt-account-center--sign-out') public onClick() {
    this.store$.dispatch(redirectTo('signout'));
  }

  constructor(
    private readonly store$: Store<AppState>,
    private readonly ngZone: NgZone,
    private readonly matomoTracker: MatomoTracker,
    private window: Window
  ) {
    this.route$ = this.store$.select(getRouterStateUrl);
    this.menuItems$ = this.store$.select(getHeaderMenuItems);
    this.envName = this.window.configurations.name;

    this.route$.pipe(filter((route) => !!route)).subscribe((route) => {
      return (this.containerClass =
        route.indexOf('/dashboard/gatekeeper') === 0
          ? 'container-fluid'
          : 'container');
    });
  }

  public closeMenu(item) {
    switch (item.key) {
      case 'home':
        this.matomoTracker.trackEvent(
          PiwikCategories.HomePage,
          PiwikActions.ClickOnHomeRoute
        );
        break;
      case 'export':
        this.matomoTracker.trackEvent(
          PiwikCategories.ExportPage,
          PiwikActions.ClickOnExportRoute
        );
        break;
      case 'dashboard':
        this.matomoTracker.trackEvent(
          PiwikCategories.DashboardPage,
          PiwikActions.ClickOnDashboardRoute
        );
        break;
      case 'approvals':
        this.matomoTracker.trackEvent(
          PiwikCategories.ApprovalPage,
          PiwikActions.ClickOnApprovalRoute
        );
        break;
      case 'contacts-details':
        this.matomoTracker.trackEvent(
          PiwikCategories.ContactsDetailsPage,
          PiwikActions.ClickOnContactsDetailsRoute
        );
        break;
      case 'delegation':
        this.matomoTracker.trackEvent(
          PiwikCategories.DelegationPage,
          PiwikActions.ClickOnDelegationRoute
        );
        break;
      default:
        break;
    }
    this.ngZone.runOutsideAngular(() => {
      window.$('#sgxb-menu-navbar').collapse('hide');
    });
  }
}
