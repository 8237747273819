import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { hasAccessToContactsDetailsPage } from '@shared/utils/access/access.utils';
import {
  redirectToHome,
  showDangerNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import { getCurrentUserAccesses } from '@store/root/root.selectors';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactsDetailsGuard implements CanActivate, CanActivateChild {
  constructor(private readonly store: Store<AppState>) {}

  public canActivate(): Observable<boolean> {
    return this.store.select(getCurrentUserAccesses).pipe(
      skipWhile((user) => !user),
      map((accesses) => {
        if (!hasAccessToContactsDetailsPage(accesses)) {
          this.store.dispatch(redirectToHome());
          this.store.dispatch(
            showDangerNotification('You are not authorized!')
          );
          return false;
        }
        return true;
      })
    );
  }

  public canActivateChild(): Observable<boolean> {
    return this.store.select(getCurrentUserAccesses).pipe(
      skipWhile((user) => !user),
      map((accesses) => {
        if (!hasAccessToContactsDetailsPage(accesses)) {
          this.store.dispatch(redirectToHome());
          this.store.dispatch(
            showDangerNotification('You are not authorized!')
          );
          return false;
        }
        return true;
      })
    );
  }
}
