import { Component, Input } from '@angular/core';
import { Validator } from '@angular/forms';
import { CtaAnswerResultType } from '@models/ComplianceTravelApproval';
import { Store } from '@ngrx/store';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  updateCtaAnswer,
  updateCtaAnswerValidity,
} from '@store/cta/cta.actions';
import { AppState } from '@store/root/root.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-input',
  templateUrl: './travel-approval-input.component.html',
  styleUrls: ['./travel-approval-input.component.scss'],
})
export class TravelApprovalInputComponent {
  @Input('question') public q: UITravelApprovalQuestion;

  public answer$: Observable<CtaAnswerResultType>;
  public validators: Validator[];

  constructor(private readonly store$: Store<AppState>) {}

  public onInput(event): void {
    this.store$.dispatch(updateCtaAnswer(this.q.uuid, event.target.value));
  }

  public onValidate(isValidated: boolean): void {
    this.store$.dispatch(updateCtaAnswerValidity(this.q.uuid, isValidated));
  }
}
