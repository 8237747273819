import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountriesResponse, Country } from '@models/Country';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { ConfigureCountryRouteRegex } from '@shared/utils/regex/routes.regex';
import { fetchGuidelineForConfiguration } from '@store/guideline/edit/guideline-edit.actions';
import {
  redirectTo,
  showSuccessNotification,
  showWarningNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import { getRouterStateUrl } from '@store/root/root.selectors';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  FetchCountries,
  FetchCountriesUnderOwnership,
  FetchCountryByCountryCode,
  SaveGuidelineConfiguration,
  fetchCountriesFail,
  fetchCountriesSuccess,
  fetchCountriesUnderOwnershipFail,
  fetchCountriesUnderOwnershipSuccess,
  fetchCountryByCountryCode,
  fetchCountryFail,
  fetchCountrySuccess,
  saveGuidelineConfigurationFail,
  saveGuidelineConfigurationSuccess,
} from './country.actions';
import { encodeGuidelineConfigurationUpdateRequest } from './country.effects.utils';

@Injectable()
export class CountryEffects {
  public fetchCountryByCountryCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCountryByCountryCode>('FETCH_COUNTRY'),
      switchMap(({ countryCode: param }) =>
        this.http.get(`countries/${param}`).pipe(
          map((res: Country) => fetchCountrySuccess(res)),
          catchError((err) => of(fetchCountryFail(err)))
        )
      )
    )
  );

  public saveGuidelineConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveGuidelineConfiguration>('SAVE_GUIDELINE_CONFIGURATION'),
      switchMap(({ payload: c }) =>
        this.http
          .put(
            `countries/${c.alpha2}`,
            encodeGuidelineConfigurationUpdateRequest(c)
          )
          .pipe(
            mergeMap((res: Country) => {
              return [
                saveGuidelineConfigurationSuccess(res),
                fetchCountryByCountryCode(res.alpha2), // Remove this when the API is fixed
                fetchGuidelineForConfiguration(res.alpha2),
                redirectTo('/configure', res.alpha2),
                showSuccessNotification(
                  `You have updated ${res.name} guidelines under your ownership.`
                ),
              ];
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                saveGuidelineConfigurationFail(err),
                showWarningNotification(err.error.message),
              ];
            })
          )
      )
    )
  );

  public fetchCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCountries>('FETCH_COUNTRIES'),
      mergeMap(({ underPerimeter, withGuidelines }) => {
        let params = new HttpParams();
        if (underPerimeter) {
          params = params.set('underMyPerimeter', underPerimeter.toString());
        }
        if (withGuidelines) {
          params = params.set('withGuidelines', withGuidelines.toString());
        }

        return this.http
          .get('countries', {
            params,
          })
          .pipe(
            map((res: CountriesResponse) =>
              fetchCountriesSuccess(
                res.countries ?? [],
                underPerimeter,
                withGuidelines
              )
            ),
            catchError((err) =>
              of(fetchCountriesFail(err, underPerimeter, withGuidelines))
            )
          );
      })
    )
  );

  public fetchCountriesUnderOwnership$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCountriesUnderOwnership>('FETCH_COUNTRIES_UNDER_OWNERSHIP'),
      mergeMap(() =>
        this.http
          .get('countries', { params: { underMyPerimeter: 'true' } })
          .pipe(
            map((res: CountriesResponse) =>
              fetchCountriesUnderOwnershipSuccess(res.countries ?? [])
            ),
            catchError((err) => of(fetchCountriesUnderOwnershipFail(err)))
          )
      )
    )
  );

  public navigateToEditCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterStateUrl),
        (_action, router) => router
      ),
      filter((url) => ConfigureCountryRouteRegex.test(url)),
      map<string, string>((url) => url.match(ConfigureCountryRouteRegex)[1]),
      mergeMap((countryCode) => {
        return [fetchCountryByCountryCode(countryCode)];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store$: Store<AppState>
  ) {}
}
