import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/root/root.reducer';
import { getFakeSSOUser } from '@store/root/root.selectors';
import { sgwtConnect, sgwtConnectPredicate } from 'app/sgwt-connect';
import { HTTP_HEADERS } from 'app/utils/header.utils';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private fakeSSOUser$: Observable<string>;

  constructor(private readonly store$: Store<AppState>) {
    this.fakeSSOUser$ = this.store$.select(getFakeSSOUser);
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!sgwtConnectPredicate(request)) {
      return next.handle(request);
    }

    return <Observable<HttpEvent<any>>>this.fakeSSOUser$.pipe(
      mergeMap((user) => {
        const token = sgwtConnect.getAuthorizationHeader();

        const headers =
          window.configurations.sgConnect.allowFakeSSO && user
            ? {
                [HTTP_HEADERS.authorization]: token,
                [HTTP_HEADERS.fakeSso]: user,
              }
            : {
                [HTTP_HEADERS.authorization]: token,
              };
        request = request.clone({ setHeaders: headers });

        return next.handle(request);
      })
    );
  }
}
