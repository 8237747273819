import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessUnitsResponse } from '@models/BusinessUnit';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { showWarningNotification } from '@store/header/header.actions';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import {
  FetchBusinessUnits,
  fetchBusinessUnits,
  fetchBusinessUnitsFail,
  fetchBusinessUnitsSuccess,
} from './business-unit.actions';

@Injectable()
export class BusinessUnitEffects {
  public fetchAllBusinessUnitsOnInit = createEffect(() =>
    this.actions$.pipe(
      first(),
      map(() => fetchBusinessUnits())
    )
  );

  public fetchBusinessUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBusinessUnits>('FETCH_BUSINESS_UNITS'),
      switchMap(({ underPerimeter }) => {
        let params = new HttpParams();
        if (underPerimeter) {
          params = params.set('underMyPerimeter', underPerimeter.toString());
        }

        return this.http
          .get('business-units', {
            params,
          })
          .pipe(
            map((res: BusinessUnitsResponse) =>
              fetchBusinessUnitsSuccess(res.businessUnits ?? [], underPerimeter)
            ),
            catchError((err: HttpErrorResponse) => {
              return [
                fetchBusinessUnitsFail(underPerimeter),
                showWarningNotification(err.error.message),
              ];
            })
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient
  ) {}
}
