import { Component, Input, OnInit } from '@angular/core';
import { CtaAnswerResultType } from '@models/ComplianceTravelApproval';
import { Validator } from '@models/Validator';
import { Store } from '@ngrx/store';
import { getValidators } from '@routes/travel-approval/helpers/travel-approval.helper';
import {
  removeLineBreakTags,
  removeParagraphTags,
} from '@shared/utils/rich-text-editor/rich-text-editor.utils';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  updateCtaAnswer,
  updateCtaAnswerValidity,
} from '@store/cta/cta.actions';
import { AppState } from '@store/root/root.reducer';
import { getAnswer } from '@store/root/root.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-acknowledgement',
  templateUrl: './travel-approval-acknowledgement.component.html',
  styleUrls: ['./travel-approval-acknowledgement.component.scss'],
})
export class TravelApprovalAcknowledgementComponent implements OnInit {
  @Input('question') public q: UITravelApprovalQuestion;
  public answer$: Observable<CtaAnswerResultType>;
  public validators: Validator[];

  constructor(private readonly store$: Store<AppState>) {}

  public get acknowledgementDescription(): string {
    return removeLineBreakTags(removeParagraphTags(this.q.description));
  }

  public ngOnInit() {
    this.answer$ = this.store$.select(getAnswer, { uuid: this.q.uuid });
    this.validators = getValidators(this.q.type);
  }

  public onAcknowledgementChange(event: any): void {
    this.store$.dispatch(
      updateCtaAnswer(this.q.uuid, event.target.checked ? 'Yes' : 'No')
    );
  }

  public onValidate(isValidated: boolean): void {
    this.store$.dispatch(updateCtaAnswerValidity(this.q.uuid, isValidated));
  }
}
