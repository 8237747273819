import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormFieldValidator } from '@models/Validator';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  FetchCommonFormValidator,
  FetchSupervisorFieldValidator,
  fetchCommonFormValidatorFail,
  fetchCommonFormValidatorSuccess,
  fetchSupervisorFieldValidatorFail,
  fetchSupervisorFieldValidatorSuccess,
} from './validator.actions';

@Injectable()
export class ValidatorEffects {
  public fetchCommonFormValidator$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCommonFormValidator>('FETCH_COMMON_FORM_VALIDATOR'),
      switchMap(({ traveler }) => {
        return this.http$
          .get(
            'gateway/formBuilder',
            traveler
              ? {
                  params: {
                    traveler,
                  },
                }
              : undefined
          )
          .pipe(
            map((res: FormFieldValidator[]) => {
              return fetchCommonFormValidatorSuccess(res);
            }),
            catchError((err: HttpErrorResponse) =>
              of(fetchCommonFormValidatorFail(err))
            )
          );
      })
    )
  );

  public fetchSupervisorFieldValidator$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSupervisorFieldValidator>('FETCH_SUPERVISOR_FIELD_VALIDATOR'),
      switchMap(({ countryCode }) =>
        this.http$
          .get('gateway/formBuilder/supervisor', {
            params: {
              destination: countryCode,
            },
          })
          .pipe(
            map((res: FormFieldValidator) =>
              fetchSupervisorFieldValidatorSuccess(res)
            ),
            catchError((err: HttpErrorResponse) =>
              of(fetchSupervisorFieldValidatorFail(err))
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly http$: HttpClient
  ) {}
}
