import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DraggablePanelComponent } from '../draggable-panel/draggable-panel.component';

@Component({
  selector: 'sgxb-sortable-panels',
  templateUrl: './sortable-panels.component.html',
  styleUrls: ['./sortable-panels.component.scss'],
})
export class SortablePanelsComponent implements AfterContentInit, OnDestroy {
  @ViewChild('row', { static: false }) public row;
  @ContentChildren(DraggablePanelComponent)
  public panels: QueryList<DraggablePanelComponent>;
  @Output() public reorder = new EventEmitter<number[]>();

  private moving: MovingPanel;
  private ghost: any;
  private previousCoords: any;

  private panelsChangeSubscription: Subscription;
  private mousedownSubscription: Subscription[] = [];

  constructor(private readonly ngZone: NgZone, private renderer: Renderer2) {}

  public ngAfterContentInit() {
    this.panels.forEach((panelInstance) => {
      this.mousedownSubscription.push(
        panelInstance.mousedown.subscribe((e) =>
          this.mousedown(e, panelInstance)
        )
      );
    });
    this.panelsChangeSubscription = this.panels.changes.subscribe(
      (newPanels) => {
        this.panels = newPanels;
        while (this.mousedownSubscription.length) {
          this.mousedownSubscription.pop().unsubscribe();
        }
        this.panels.forEach((panelInstance) => {
          this.mousedownSubscription.push(
            panelInstance.mousedown.subscribe((e) =>
              this.mousedown(e, panelInstance)
            )
          );
        });
      }
    );
  }

  public ngOnDestroy() {
    this.panelsChangeSubscription.unsubscribe();
    while (this.mousedownSubscription.length) {
      this.mousedownSubscription.pop().unsubscribe();
    }
  }

  @HostListener('window:scroll.out-zone', [])
  @HostListener('document:mousemove.out-zone', ['$event'])
  public mousemove(e) {
    this.ngZone.runOutsideAngular(() => {
      if (!this.moving) {
        return;
      }
      if (!e) {
        e = this.previousCoords;
      }

      let finalY = Math.max(
        e.clientY - this.moving.offsetY + window.scrollY,
        this.moving.minY
      );
      if (finalY + this.moving.container.offsetHeight > this.moving.maxY) {
        finalY = this.moving.maxY - this.moving.container.offsetHeight;
      }
      this.moving.panel.style.top = finalY + 'px';

      this.panels.some((panelInstance) => {
        const container = panelInstance.container.nativeElement;
        const panel = container.parentElement;
        if (panel === this.moving.panel) {
          return false;
        }

        // Going down
        if (
          this.previousCoords.clientY < e.clientY &&
          finalY + this.moving.panel.offsetHeight >=
            container.offsetTop + container.offsetHeight
        ) {
          this.row.nativeElement.insertBefore(
            this.ghost,
            panel.nextElementSibling
          );
        } else if (
          this.previousCoords.clientY > e.clientY &&
          finalY <= container.offsetTop
        ) {
          this.row.nativeElement.insertBefore(this.ghost, panel);
          return true;
        }

        return false;
      });

      this.previousCoords = e;
    });
  }

  public mousedown(e, panelInstance) {
    this.ngZone.runOutsideAngular(() => {
      if (this.ghost) {
        return;
      }

      this.createMovingPanel(e, panelInstance);
      this.createGhost(e);

      Object.assign(this.moving.panel.style, {
        position: 'absolute',
        width: this.moving.container.offsetWidth + 'px',
        height: this.moving.container.offsetHeight + 'px',
        zIndex: 99999,
        boxShadow: '0 2px 8px #333',
        left: this.moving.panel.parentElement.offsetLeft + 'px',
      });
      this.previousCoords = e;
      this.row.nativeElement.style.cursor = 'move';
      this.row.nativeElement.style.userSelect = 'none';
      this.mousemove(e);
    });
  }

  @HostListener('document:mouseup.out-zone')
  public mouseup() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.moving) {
        return;
      }
      this.moving.panel.style.cssText = '';
      this.moving.container.style.cssText = '';
      this.row.nativeElement.replaceChild(this.moving.panel, this.ghost);
      this.row.nativeElement.style.cursor = '';
      this.row.nativeElement.style.userSelect = '';
      this.moving = null;
      this.ghost = null;
      this.ngZone.run(() => {
        const reorderedItems = new Array<number>();
        for (const elem of this.row.nativeElement.children) {
          this.panels.forEach((panelInstance, index) => {
            const panel = panelInstance.container.nativeElement.parentElement;
            if (panel === elem) {
              reorderedItems.push(index);
            }
          });
        }
        this.reorder.emit(reorderedItems);
      });
    });
  }

  public createMovingPanel(e, panelInstance) {
    const panel = panelInstance.container.nativeElement.parentElement;
    const container = panelInstance.container.nativeElement;
    this.moving = {
      panel,
      container,
      minY: panel.parentElement.offsetTop,
      maxY: panel.parentElement.offsetTop + panel.parentElement.offsetHeight,
      offsetY: e.clientY - container.offsetTop + window.scrollY,
    };
  }

  public createGhost(_e) {
    this.ghost = this.renderer.createElement('div');
    this.ghost.className = 'ghost panel';
    Object.assign(this.ghost.style, {
      width: this.moving.container.offsetWidth + 'px',
      height: this.moving.container.offsetHeight - 6 + 'px',
    });
    this.row.nativeElement.insertBefore(this.ghost, this.moving.panel);
  }
}

class MovingPanel {
  /** The <sgxb-draggable-panel /> element */
  public panel: any;
  /** The first global container inside the <sgxb-draggable-panel /> element */
  public container: any;
  /** Minimum Y coordinate for the moving panel */
  public minY: number;
  /** Maximum Y coordinate for the moving panel (for the bottom border) */
  public maxY: number;
  /**
   * The Y offset of the cursor when the user clicked on the header
   * (from the top left of the container).
   */
  public offsetY: number;
}
