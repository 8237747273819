import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Acknowledgement } from '@models/Acknowledgement';
import { Guideline } from '@models/Guideline';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import {
  hasReadOnlyGuidelinesPermission,
  hasReadWriteGuidelinesPermission,
} from '@shared/utils/access/access.utils';
import { ViewGuidelineRouteRegex } from '@shared/utils/regex/routes.regex';
import { fetchAcknowledgements } from '@store/acknowledgement/acknowledgement.actions';
import { fetchBusinessUnitForGuideline } from '@store/guideline/guideline.actions';
import {
  redirectToHome,
  showSuccessNotification,
  showWarningNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import {
  getGuidelineForDisplay,
  getRouterStateUrl,
} from '@store/root/root.selectors';
import { FetchCurrentUserAccessesSucceed } from '@store/user/user.actions';
import { Observable, of } from 'rxjs';
import {
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { SubmitQuestionRequest } from '../guideline.models';
import {
  Acknowledge,
  AcknowledgeSuccess,
  FetchDisplaySubmitCaseAndProfiles,
  FetchGuideline,
  FetchGuidelineFail,
  FetchGuidelineSuccess,
  GuidelineViewFilterSelect,
  SubmitQuestion,
  SubmitQuestionDone,
  acknowledgeFail,
  acknowledgeSuccess,
  fetchDisplaySubmitCaseAndProfiles,
  fetchDisplaySubmitCaseAndProfilesFail,
  fetchDisplaySubmitCaseAndProfilesSuccess,
  fetchGuideline,
  fetchGuidelineFail,
  fetchGuidelineSuccess,
  submitQuestionDone,
} from './guideline-view.actions';
import { encodeSubmitQuestionRequest } from './guideline-view.effects.utils';

@Injectable()
export class GuidelineViewEffects {
  // Route: /view/${countryCode}
  public navigateToView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterStateUrl),
        (_action, router) => router
      ),
      filter((url) => ViewGuidelineRouteRegex.test(url)),
      map<string, string>((url) => url.match(ViewGuidelineRouteRegex)[1]),
      combineLatest(
        this.actions$.pipe(
          ofType<FetchCurrentUserAccessesSucceed>(
            'FETCH_CURRENT_USER_ACCESSES_SUCCEED'
          )
        )
      ),
      mergeMap(([countryCode, { access }]) => {
        const actions: Action[] = [fetchGuideline(countryCode)];
        if (
          hasReadWriteGuidelinesPermission(access) ||
          hasReadOnlyGuidelinesPermission(access)
        ) {
          actions.push(fetchBusinessUnitForGuideline(countryCode));
        }
        return actions;
      })
    )
  );

  public fetchGuideline$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGuideline>('FETCH_GUIDELINE'),
      switchMap(({ countryCode, businessUnit }) => {
        const url = `gateway/guidelines/${countryCode}${
          businessUnit ? `?businessUnit=${businessUnit}` : ''
        }`;
        return this.http.get(url).pipe(
          map((res: Guideline) => fetchGuidelineSuccess(res)),
          catchError((err: HttpErrorResponse) => {
            return [
              showWarningNotification(err.error.message),
              fetchGuidelineFail(err),
            ];
          })
        );
      })
    )
  );

  public fetchGuidelineSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGuidelineSuccess>('FETCH_GUIDELINE_SUCCESS'),
      switchMap((_) => [fetchDisplaySubmitCaseAndProfiles()])
    )
  );

  public fetchFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGuidelineFail>('FETCH_GUIDELINE_FAIL'),
      map(() => redirectToHome())
    )
  );

  public acknowledge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Acknowledge>('ACKNOWLEDGE'),
      switchMap((action) =>
        this.http
          .post(`gateway/acknowledgements/${action.country.alpha2}`, {})
          .pipe(
            map((res: Acknowledgement) => acknowledgeSuccess(res)),
            catchError((err: HttpErrorResponse) => {
              return [
                showWarningNotification(err.error.message),
                acknowledgeFail(err),
              ];
            })
          )
      )
    )
  );

  public acknowledgeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AcknowledgeSuccess>('ACKNOWLEDGE_SUCCESS'),
      switchMap((action) => [
        redirectToHome(),
        showSuccessNotification(
          `You have acknowledged ${action.acknowledgement.country.name} cross-border guidelines.`
        ),
        fetchAcknowledgements(),
      ])
    )
  );

  public fetchDisplaySubmitPracticalCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDisplaySubmitCaseAndProfiles>(
        'FETCH_DISPLAY_SUBMIT_CASE_AND_PROFILES'
      ),
      withLatestFrom(this.store$.select(getGuidelineForDisplay)),
      switchMap(([_, guideline]) =>
        this.http
          .get(
            `gateway/actions/${guideline.country.alpha2}/submitPracticalCases`
          )
          .pipe(
            map((res: string) => fetchDisplaySubmitCaseAndProfilesSuccess(res)),
            catchError((err: HttpErrorResponse) =>
              of(fetchDisplaySubmitCaseAndProfilesFail(err.error.message))
            )
          )
      )
    )
  );

  public submitQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitQuestion>('SUBMIT_QUESTION'),
      withLatestFrom(this.store$.select(getGuidelineForDisplay)),
      switchMap(([action, g]) => {
        const endpointSubmitQuestion =
          action && action.profile ? `/${action.profile}` : '';
        const body: SubmitQuestionRequest = {
          question: action.question,
        };
        return this.http
          .post(
            `gateway/ask/${g.country.alpha2}${endpointSubmitQuestion}`,
            encodeSubmitQuestionRequest(body)
          )
          .pipe(
            map((_res: string) =>
              submitQuestionDone(
                true,
                'You have submitted your question(s) to compliance.'
              )
            ),
            catchError((err: HttpErrorResponse) =>
              of(submitQuestionDone(false, err.error.message))
            )
          );
      })
    )
  );

  public submitQuestionDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitQuestionDone>('SUBMIT_QUESTION_DONE'),
      map(({ status, message }) =>
        (status ? showSuccessNotification : showWarningNotification)(message)
      )
    )
  );

  public fetchGuidelineByCountryAndBusinessUnitForView = createEffect(() =>
    this.actions$.pipe(
      ofType<GuidelineViewFilterSelect>('GUIDELINE_VIEW_FILTER_SELECT'),
      withLatestFrom(this.store$.select(getGuidelineForDisplay)),
      switchMap(([action, updatedGuideline]) => {
        const businessUnit =
          action.businessUnit === 'ALL' ? undefined : action.businessUnit;
        return [fetchGuideline(updatedGuideline.country.alpha2, businessUnit)];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store$: Store<AppState>
  ) {}
}
