import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Modal } from '@models/Modal';
import { User } from '@models/User';
import { Store } from '@ngrx/store';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { AppState } from '@store/root/root.reducer';
import {
  getCurrentUser,
  getModal,
  getRouterStateUrl,
} from '@store/root/root.selectors';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

@Component({
  selector: 'sgxb-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public currentUser$: Observable<User>;
  public modal$: Observable<Modal>;
  public route$: Observable<string>;
  public marginBottom$: Observable<boolean>;

  private readonly _subscriptions: Subscription[];

  constructor(
    private readonly router: Router,
    private readonly store$: Store<AppState>,
    private readonly matomoTracker: MatomoTracker
  ) {
    this._subscriptions = [];
    this.currentUser$ = this.store$.select(getCurrentUser);
    this.modal$ = this.store$.select(getModal);
    this.route$ = this.store$.select(getRouterStateUrl);

    this.marginBottom$ = this.route$.pipe(
      filter((route) => {
        return !!route;
      }),
      map((route) => {
        return !(route.indexOf('/dashboard/gatekeeper') === 0);
      })
    );

    this._subscriptions.push(
      this.store$
        .select(getCurrentUser)
        .pipe(
          filter((user) => user !== null && user !== undefined),
          first(),
          tap((user) => {
            this.matomoTracker.setUserId(user.email);
            this.matomoTracker.setCustomVariable(
              1,
              'Department',
              user.originalDepartment ?? user.department ?? 'UNKNOWN',
              'visit'
            );
            this.matomoTracker.setCustomVariable(
              2,
              'Business unit',
              user.businessUnit ?? 'UNKNOWN',
              'visit'
            );
          })
        )
        .subscribe()
    );
  }

  public ngOnInit() {
    // Router events
    this._subscriptions.push(
      this.router.events
        .pipe(filter((evt) => evt instanceof NavigationEnd))
        .subscribe(() => {
          // Scroll back to the top on route change
          window.scrollTo(0, 0);
        })
    );
  }

  public ngOnDestroy() {
    for (const subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
  }
}
