import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from '@models/Breadcrumb';

@Component({
  selector: 'sgxb-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() public breadcrumbItems: BreadcrumbItem[];

  constructor() {
    // Expected empty constructor
  }
}
