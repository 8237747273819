import { Component, Input, OnInit } from '@angular/core';
import {
  CtaAnswerResultType,
  CtaOption,
} from '@models/ComplianceTravelApproval';
import { Validator } from '@models/Validator';
import { Store } from '@ngrx/store';
import { getValidators } from '@routes/travel-approval/helpers/travel-approval.helper';
import { UITravelApprovalQuestion } from '@store/cta-specific/cta-specific.models';
import {
  updateCtaAnswerOnMultiSelectAdd,
  updateCtaAnswerOnMultiSelectRemove,
  updateCtaAnswerValidity,
} from '@store/cta/cta.actions';
import { AppState } from '@store/root/root.reducer';
import { getAnswer } from '@store/root/root.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgxb-travel-approval-multi-select',
  templateUrl: './travel-approval-multi-select.component.html',
  styleUrls: ['./travel-approval-multi-select.component.scss'],
})
export class TravelApprovalMultiSelectComponent implements OnInit {
  @Input('question') public q: UITravelApprovalQuestion;
  public answer$: Observable<CtaAnswerResultType>;
  public validators: Validator[];

  constructor(private readonly store$: Store<AppState>) {}

  public ngOnInit() {
    this.answer$ = this.store$.select(getAnswer, { uuid: this.q.uuid });
    this.validators = getValidators(this.q.type);
  }

  public onMultiSelectAdd(value: CtaOption) {
    this.store$.dispatch(
      updateCtaAnswerOnMultiSelectAdd(this.q.uuid, value.text)
    );
  }

  public onMultiSelectRemove(value: CtaOption) {
    this.store$.dispatch(
      updateCtaAnswerOnMultiSelectRemove(this.q.uuid, value.text)
    );
  }

  public onValidate(isValidated: boolean): void {
    this.store$.dispatch(updateCtaAnswerValidity(this.q.uuid, isValidated));
  }
}
