import { Component, Input } from '@angular/core';
import { ComplianceTravelApproval } from '@models/ComplianceTravelApproval';

@Component({
  selector: 'sgxb-travel-approval-status-breakdown',
  templateUrl: './travel-approval-status-breakdown.component.html',
  styleUrls: ['./travel-approval-status-breakdown.component.scss'],
})
export class TravelApprovalStatusBreakdownComponent {
  @Input() public cta: ComplianceTravelApproval;
  @Input() public collapsed: boolean = true;

  constructor() {
    // Expected empty constructor
  }

  public disableSortingInPipe() {
    return 0;
  }
}
