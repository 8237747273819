import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Modal } from '@models/Modal';

@Component({
  selector: 'sgxb-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges {
  @ViewChild('toggleButton', { static: false }) public toggleButton: ElementRef;

  @Input() public modal: Modal;

  public active = false;

  constructor(private readonly ngZone: NgZone) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('modal') &&
      ((changes.modal.currentValue.title &&
        changes.modal.currentValue.title !== '') ||
        (changes.modal.currentValue.content &&
          changes.modal.currentValue.content !== ''))
    ) {
      if (!this.active) {
        this.toggle();
      }
    }
  }

  public toggle() {
    this.ngZone.runOutsideAngular(() => {
      this.toggleButton.nativeElement.click();
    });
    this.active = !this.active;
  }

  public dismiss() {
    this.active = false;
    if (
      this.modal.failCallback &&
      typeof this.modal.failCallback === 'function'
    ) {
      this.modal.failCallback();
    }
  }

  public ok() {
    this.active = false;
    if (
      this.modal.successCallback &&
      typeof this.modal.successCallback === 'function'
    ) {
      this.modal.successCallback();
    }
  }
}
