import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MOCK_FORM_VALIDATOR,
  MOCK_FORM_VALIDATOR_MULTIPLE_FORBIDDEN_MANAGERS,
  MOCK_SUPERVISOR_VALIDATOR,
} from '@mock-api/mocks/validators.mocks';
import { ok } from 'app/mock-api/utils/response.utils';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

@Injectable()
export class FormValidatorRequestInterceptor implements HttpInterceptor {
  constructor() {
    // Expected empty constructor
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <Observable<HttpEvent<any>>>of(null).pipe(
      mergeMap(() => {
        if (
          request.method === 'GET' &&
          request.url.match(/^\/formBuilder$/) &&
          request.params.get('traveler') &&
          request.params
            .get('traveler')
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(socgen|sgcib).(com)(.[a-z]{2})?$/
            )
        ) {
          return ok(MOCK_FORM_VALIDATOR_MULTIPLE_FORBIDDEN_MANAGERS);
        } else if (
          request.url.match(/^\/formBuilder$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_FORM_VALIDATOR);
        } else if (
          request.url.match(/^\/formBuilder\/supervisor$/) &&
          request.params.get('destination').match(/^([A-Z]{2})$/) &&
          request.method === 'GET'
        ) {
          return ok(MOCK_SUPERVISOR_VALIDATOR);
        }
        return next.handle(request);
      }),
      materialize(),
      delay(300),
      dematerialize()
    );
  }
}
